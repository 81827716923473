import React from 'react';
import styled from 'styled-components';
import { device } from '../utils/breakpoints';

const Wrapper = styled.aside`
  background-color: rgba(0, 0, 0, 0.15);
  display: none;
  font-size: 24px;
  color: var(--color-gray-700);

  @media ${device.medium} {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Sidebar = () => {
  return <Wrapper>👷 Coming Soon 👷</Wrapper>;
};

export default Sidebar;
