import React, { useState } from 'react';

import styled, { keyframes } from 'styled-components';
import Header from '../Header/header';

import { device } from './../../utils/breakpoints';
import ClientOnly from '../ClientOnly';

const HeroWrapper = styled.section`
  position: relative;
  min-height: 40vh;
  border: 0;
  /* margin-left: -1em; */
  margin-top: -1em;
  /* margin-right: -1em; */
  width: 100%;
  background-image: var(--background-hero);


  @media ${device.medium} {
    min-height: 50vh;
  }
`;

const SwoopWrapper = styled.div`
  overflow: hidden;
  display: block;
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 100%;
  z-index: 3;
  line-height: 0;

  /* transform: translateY(10px); */

  svg {
    position: relative;
    bottom: 0px;
    fill: var(--color-background);
    height: 150px;
    width: calc(100% + 1.3px);
    transform: rotate(180deg);
    transition: fill 300ms ease-in-out 0s;
  }
`;

const AboutMeText = styled.p`
  /* display: flex; */
  /* position: absolute; */
  /* justify-content: center;
  align-items: center; */
  max-width: 400px;
  text-align: center;
  i {
    font-size: 14px;
  }

  h2 {
    text-align: center;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  /* bottom: 0;
  height: 100%;  */
`;

const AboutMeWrapper = styled.div`
  display: flex;
  position: relative;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 400px;
  max-width: ${device.medium};
  height: 100%;
  padding: 0 64px;
  min-height: 440px;
  z-index: 3;
  margin: 0 auto;
  margin-bottom: 24px;

  svg {
    display: none;
  }

  @media ${device.medium} {
    svg {
      display: block;
    }
    width: 600px;
  }
`;

const Hero = () => {
  return (
    <HeroWrapper>
      <Header></Header>
      <ClientOnly>
        <AboutMeWrapper>
          <AboutMeText>
            <i>Hello, I am</i>
            <h2>George Nance</h2> I am a professional web developer, a writer,
            designer, and a lover of chocolate.
          </AboutMeText>
        </AboutMeWrapper>
      </ClientOnly>
      <SwoopWrapper>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            opacity=".25"
            id="shape1"
            class="shape-fill"
          ></path>
          <path
            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
            opacity=".5"
            id="shape2"
            class="shape-fill"
          ></path>
          <path
            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
            class="shape-fill"
          ></path>
        </svg>
      </SwoopWrapper>
    </HeroWrapper>
  );
};

export default Hero;
