import React from 'react';

import { graphql } from 'gatsby';
import Hero from '../components/Hero/hero';

import Footer from '../components/Footer/Footer';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import styled from 'styled-components';
import Sidebar from '../components/Sidebar';
import DevelopmentOnly from '../components/Development-Only';
import SEO from '../components/SEO';
import BlogList from '../components/BlogList/BlogList';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export default ({ data, location }) => {
  return (
    <>
      <SEO title="George Nance"></SEO>
      <Hero></Hero>

      <MaxWidthWrapper>
        <BodyWrapper>
          <BlogList posts={data.allMdx.edges} />
          <DevelopmentOnly>
            <Sidebar></Sidebar>
          </DevelopmentOnly>
        </BodyWrapper>
      </MaxWidthWrapper>
      <Footer></Footer>
    </>
  );
};

export const query = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { collection: { eq: "posts" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            published
            slug
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt(pruneLength: 280)
        }
      }
    }
  }
`;
